@media screen and (max-width: 1024px) {
  #counter {
    .counter-num {
      font-size: 80px;
      font-weight: 500;
    }
  }
  .app-store-title {
    font-size: 56px;
  }
  .item-row-gap {
    row-gap: 1.5rem;
  }
}
@media screen and (max-width: 991px) {
  .item-row-gap {
    row-gap: 0.8rem;
  }
}

@media screen and (max-width: 768px) {
  .home-banner{
    height: 380px;
  }
  .content-title {
    font-size: 30px;
  }
  .blog-item {
    .blog-content {
      h4 {
        font-size: 20px;
      }
    }
  }

  #counter {
    .counter-num {
      font-size: 72px;
    }
    .counter-title {
      font-size: 32px;
    }
  }
  .app-store-title {
    font-size: 48px;
    br {
      display: none;
    }
  }
}
@media only screen and (max-width: 767px) {
  .navbar{
    .custom-navbar{
      flex-basis: unset;
      flex-grow: unset;
      width: 70%;
      margin-left: auto;
      min-width: 100px;
      max-width: 250px;
      min-height: 300px;
      border-radius: 8px;
      top: 62px;
      right: 0.7rem;
      position: fixed;
      overflow: hidden;
      background-color: var(--dark_surfaceVariant);
      ul{
        height: 100%;
        background-color: var(--dark_surfaceVariant);
        li{
          a{
            width: 100%;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 480px) {
  .banner-title {
    font-size: 32px;
  }
  .content-title {
    font-size: 26px;
  }
  #counter {
    .counter-num {
      font-size: 48px;
    }
  }
  .app-store-section{
    .app-store-img{
      height: 50px;
    }
  }
  .app-store-title {
    font-size: 32px;
  }
}
